import {
  Box,
  Button,
  Group,
  Input,
  LoadingOverlay,
  Space,
  Text,
  rem,
} from "@mantine/core"

import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { IconSearch, IconArrowLeft } from "@tabler/icons-react"
import { Suspense, useEffect, useState } from "react"
import { graphql, useLazyLoadQuery, useQueryLoader } from "react-relay"
import { useSearchParams, useNavigate } from "react-router-dom"
import styles from "./account-maps.module.scss"
import { ColumnDef } from "@tanstack/react-table"
import { Table } from "@shared/ui/table"
import { mapsQuery as MapsQueryType } from "./__generated__/mapsQuery.graphql"
import { MapsList } from "./maps-list"
import { MapsDrawer } from "./maps-drawer/maps-drawer"
import { MapsDrawerQuery } from "./maps-drawer/maps-drawer"
import { mapsDrawerQuery as MapsDrawerQueryType } from "./maps-drawer/__generated__/mapsDrawerQuery.graphql"



type Map = {
  name: string | null
  id: string
  owner: string | null
  createdAt: string
}

export const AccountMaps = () => {
  const { currentClient: { id: currentClientId } } = useUserStore()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const activeMapId = searchParams.get("mapId")
  const [isNewMap, setIsNewMap] = useState(false)
  const [initialClientId] = useState(currentClientId)
  const [drawerQueryRef, loadDrawerQuery] =
    useQueryLoader<MapsDrawerQueryType>(MapsDrawerQuery)

  useEffect(() => {
    if (currentClientId !== initialClientId) {
      // If client changes while viewing a specific map, redirect to maps list
      if (window.location.pathname.includes('/account-map/')) {
        navigate("/account-maps")
      }
    }
  }, [currentClientId, initialClientId, navigate])

  useEffect(() => {
    if (activeMapId && activeMapId !== 'new') {
      loadDrawerQuery({ input: activeMapId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMapId])

  const columns: ColumnDef<Map>[] = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Owner",
      accessorKey: "owner",
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
    },
  ]

  const handleRowClick = (id: string) => {
    loadDrawerQuery({ input: id })
    setSearchParams({ mapId: id })
  }

  return (
    <Box className={styles.AccountMaps} pos="relative">
      <Group justify="space-between">
        <Text size="xxl" fw="bold" c="gray">
          Account Maps
        </Text>
        <Button onClick={() => {
          setIsNewMap(true)
          setSearchParams({ mapId: 'new' })
        }}>New Map</Button>
      </Group>

      <Box>
        <Space h={rem(56)} />
        <Suspense fallback={<LoadingOverlay visible />}>
          <MapsList
            columns={columns}
            onRowClick={handleRowClick}
          />
        </Suspense>

        <Suspense>
          {(drawerQueryRef || isNewMap) && (
            <MapsDrawer
              onClose={() => {
                setSearchParams({})
                setIsNewMap(false)
              }}
              open={!!activeMapId || isNewMap}
              queryRef={drawerQueryRef}
              isNewMap={isNewMap}
            />
          )}
        </Suspense>
      </Box>
    </Box>
  )

}
