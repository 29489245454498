import { AppDrawer } from "@shared/ui/app-drawer"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { graphql, usePreloadedQuery, PreloadedQuery } from "react-relay"
import {
  Avatar,
  Button,
  Divider,
  Flex,
  Group,
  Image,
  MultiSelect,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { mapsDrawerQuery as MapsDrawerQueryType } from "./__generated__/mapsDrawerQuery.graphql"
import { MapRow } from "./map-row"
import {
  getAvatarText,
  getDaysAgo,
  getIntegrationSlugIcon,
} from "@shared/utils/helpers"
import { useMutation, useLazyLoadQuery } from "react-relay"
import { mapsDrawerUpdateMutation as MapsDrawerUpdateMutationType } from "./__generated__/mapsDrawerUpdateMutation.graphql"
import { mapsDrawerCreateMutation as MapsDrawerCreateMutationType } from "./__generated__/mapsDrawerCreateMutation.graphql"
import { mapsDrawerGetIntegrationsQuery as MapsDrawerGetIntegrationsQueryType } from "./__generated__/mapsDrawerGetIntegrationsQuery.graphql"
import { notifications } from "@shared/ui/notifications"

interface Props {
  open: boolean
  onClose: () => void
  queryRef: PreloadedQuery<MapsDrawerQueryType>
  isNewMap?: boolean // Add new prop
}



export const MapsDrawerQuery = graphql`
  query mapsDrawerQuery($input: ID!) {
    getMapDetails(mapId: $input) {
      id
      name
      creator {
        fullName
      }
      createdAt
      mapGroups {
        nodes {
          id
          name
        }
      }
    }
  }
`

const MapsDrawerCreateMutation = graphql`
  mutation mapsDrawerCreateMutation($input: CreateMapMutationInput!) {
    createMap(input: $input) {
      clientMutationId
      map {
        id
        name
      }
    }
  }
`

const MapsDrawerUpdateMutation = graphql`
  mutation mapsDrawerUpdateMutation($input: UpdateMapInput!) {
    updateMap(input: $input) {
      clientMutationId
      map {
        id
        name
      }
    }
  }
`

const MapsDrawerGetIntegrationsQuery = graphql`
  query mapsDrawerGetIntegrationsQuery($systemType: String) {
    getIntegrations(systemType: $systemType) {
      edges {
        node {
          id
          name
          system {
            category
          }
        }
      }
    }
  }
`

export const MapsDrawer = ({
  open,
  onClose,
  queryRef,
  isNewMap = false
}: Props) => {
  const navigate = useNavigate()
  const mapDetails = queryRef
    ? usePreloadedQuery<MapsDrawerQueryType>(MapsDrawerQuery, queryRef)?.getMapDetails
    : null


  const [mapName, setMapName] = useState(mapDetails?.name ?? "")

  const [createMap] = useMutation(MapsDrawerCreateMutation)
  const [updateMap] = useMutation<MapsDrawerUpdateMutationType>(MapsDrawerUpdateMutation)

  const connectionsData = useLazyLoadQuery<MapsDrawerGetIntegrationsQueryType>(
    MapsDrawerGetIntegrationsQuery,
    { systemType: "ledger" }
  )

  const connectionOptions = connectionsData?.getIntegrations?.edges?.map(edge => ({
    value: edge?.node?.id ?? '',
    label: edge?.node?.name ?? ''
  })) ?? []

  const [selectedConnections, setSelectedConnections] = useState<string[]>(
    connectionsData?.getIntegrations?.edges?.map(edge => edge?.node?.id ?? '') ?? []
  )

  useEffect(() => {
    setMapName(mapDetails?.name ?? "")
  }, [mapDetails?.name])

  const saveButtonDisabled = isNewMap
    ? !mapName
    : mapDetails?.name === mapName || !mapName

  const handleSave = () => {
    if (isNewMap) {
      createMap({
        variables: {
          input: {
            name: mapName,
            connectionIds: selectedConnections,
          },
        },
        onCompleted: (response) => {
          if (response.createMap.errors) {
            notifications.show({
              title: "Creation failed",
              message: response.createMap.errors.join(", "),
              variant: "error",
            })
            return
          }
          notifications.show({
            title: "Map Created Successfully",
            message: "Your new map has been created successfully!",
            variant: "success",
          })
          navigate(`/account-map/${response.createMap.map.id}`)
        },
        updater: (store) => {
          // Invalidate the maps list query to trigger a refresh
          store.invalidateStore()
        },
        onError: (error) => {
          notifications.show({
            title: "Creation failed",
            message: error.message || "An unexpected error occurred while creating the map. Please try again.",
            variant: "error",
          })
        },
      })
    } else {
      mapDetails?.id &&
        updateMap({
          variables: {
            input: {
              mapId: mapDetails.id,
              name: mapName,
            },
          },
          onCompleted: () => {
            notifications.show({
              title: "Changes Saved Successfully",
              message:
                "Your changes have been saved successfully. You're all set to continue!",
              variant: "success",
            })
            onClose()
          },
          onError: () => {
            notifications.show({
              title: "Update failed",
              message:
                "An unexpected error occurred while updating the connection. Please try again.",
              variant: "error",
            })
          },
        })
    }
  }

  return (
    <AppDrawer
      opened={open}
      onClose={onClose}
      title={isNewMap ? "Create New Map" : "Map Details"}
      closeOnClickOutside={true}
    >
      <Stack justify="space-between" h="100%">
        <Stack gap="0.5rem">
          <Flex gap="0.25rem" align="flex-end">
            <TextInput
              label="Name"
              onChange={(e) => setMapName(e.target.value)}
              w="100%"
              value={mapName}
            />
            <Button
              onClick={handleSave}
              disabled={saveButtonDisabled}
            >
              {isNewMap ? "Create" : "Save"}
            </Button>
          </Flex>
          <MultiSelect
            label="Connections"
            placeholder="Select connections"
            data={connectionOptions}
            value={selectedConnections}
            onChange={setSelectedConnections}
          />
          {!isNewMap && mapDetails && (
            <Stack gap="0.5rem">
              <MapRow title="Created by">
                <Group gap={rem(4)}>
                  <Avatar size="xs" color="lightBlue.9" variant="filled">
                    {getAvatarText(mapDetails.creator.fullName ?? "")}
                  </Avatar>
                  <Text size="sm" c="gray.7">
                    {mapDetails.creator.fullName}
                  </Text>
                </Group>
              </MapRow>
              <Divider />
              <MapRow title="Created">
                <Text size="sm" c="gray.7">
                  {getDaysAgo(new Date(mapDetails.createdAt))}
                </Text>
              </MapRow>
              <Divider />
              <Button
                variant="light"
                color="blue"
                onClick={() => navigate(`/account-map/${mapDetails.id}`)}
                fullWidth
              >
                Edit Map
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </AppDrawer>
  )
}
