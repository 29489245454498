import {
  Button,
  Group,
  ButtonProps as MantineButtonProps,
  Modal,
  ModalProps,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { notifications } from "@shared/ui/notifications"
import { DOMAttributes, PropsWithChildren } from "react"
import { useForm } from "react-hook-form"
import { ConnectionHandler, graphql, useMutation } from "react-relay"
import { useSearchParams } from "react-router-dom"
import { PlaidLink } from '@/components/plaid-link/plaid-link'


type ButtonProps = DOMAttributes<HTMLButtonElement> & MantineButtonProps

type Props = ModalProps & {
  opened: boolean
  onClose: () => void
  isLoading?: boolean
}

export const BankConnectionModal = ({
  opened,
  onClose,
  ...modalProps
}: PropsWithChildren<Props>) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size={"32rem"}
      shadow="sm"
      overlayProps={{
        bg: "#3333334D",
      }}
      closeButtonProps={{
        iconSize: "1rem",
        c: themeVars.colors.gray["6"],
      }}
      withCloseButton
      closeOnClickOutside={true}
      {...modalProps}
    >
      <Stack gap="1rem">
        <Stack gap="1rem" align="center" justify="center">
          <Text>Connect Alpyne to your Bank Account</Text>
        </Stack>
        <Group justify="center" gap="1rem">
          <PlaidLink onSuccess={onClose} />
        </Group>
      </Stack>

    </Modal>
  )
}