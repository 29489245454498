/**
 * @generated SignedSource<<8a1e6c0701d0c84276882005d1b13499>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type accountMapUnmappedAccountsQuery$variables = {
  input: string;
};
export type accountMapUnmappedAccountsQuery$data = {
  readonly getUnmappedAccounts: {
    readonly accounts: ReadonlyArray<{
      readonly account: {
        readonly accountType: string | null;
        readonly integration: {
          readonly id: string;
          readonly name: string;
        };
        readonly name: string | null;
        readonly number: string | null;
        readonly systemAccountId: string | null;
      };
    }>;
  };
};
export type accountMapUnmappedAccountsQuery = {
  response: accountMapUnmappedAccountsQuery$data;
  variables: accountMapUnmappedAccountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "mapId",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemAccountId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SystemIntegration",
  "kind": "LinkedField",
  "name": "integration",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountMapUnmappedAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnmappedAccounts",
        "kind": "LinkedField",
        "name": "getUnmappedAccounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnmappedAccount",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountMapUnmappedAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnmappedAccounts",
        "kind": "LinkedField",
        "name": "getUnmappedAccounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnmappedAccount",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb39e6c68dff457d275c10d3e43d50ef",
    "id": null,
    "metadata": {},
    "name": "accountMapUnmappedAccountsQuery",
    "operationKind": "query",
    "text": "query accountMapUnmappedAccountsQuery(\n  $input: ID!\n) {\n  getUnmappedAccounts(mapId: $input) {\n    accounts {\n      account {\n        systemAccountId\n        number\n        name\n        accountType\n        integration {\n          id\n          name\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ee3c6c1c318192687ee7376373b82cb";

export default node;
