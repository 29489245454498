/**
 * @generated SignedSource<<f070f78a0d103c9c6a7888a9906fc8a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateMapInput = {
  clientMutationId?: string | null;
  mapId: string;
  name: string;
};
export type mapsDrawerUpdateMutation$variables = {
  input: UpdateMapInput;
};
export type mapsDrawerUpdateMutation$data = {
  readonly updateMap: {
    readonly clientMutationId: string | null;
    readonly map: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};
export type mapsDrawerUpdateMutation = {
  response: mapsDrawerUpdateMutation$data;
  variables: mapsDrawerUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMapPayload",
    "kind": "LinkedField",
    "name": "updateMap",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Map",
        "kind": "LinkedField",
        "name": "map",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mapsDrawerUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mapsDrawerUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "51b0cef58ae115b393787bfb6d2b0cf9",
    "id": null,
    "metadata": {},
    "name": "mapsDrawerUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation mapsDrawerUpdateMutation(\n  $input: UpdateMapInput!\n) {\n  updateMap(input: $input) {\n    clientMutationId\n    map {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "043053ad8223c034ec5a98d43679e5c9";

export default node;
