/**
 * @generated SignedSource<<12f1205622970ca573c27ce4de224a5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type IntegrationTypeEnum = "CHARGEBEE" | "GOOGLE" | "HUBSPOT" | "PLAID" | "QUICKBOOKS" | "SALESFORCE" | "SHOPIFY" | "STRIPE" | "XERO";
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
export type CreateStripeIntegrationInput = {
  apiKey: string;
  clientMutationId?: string | null;
  connectionType: IntegrationTypeEnum;
};
export type stripeConnectionModalCreateStripeIntegrationMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateStripeIntegrationInput;
};
export type stripeConnectionModalCreateStripeIntegrationMutation$data = {
  readonly createStripeIntegration: {
    readonly connection: {
      readonly creator: {
        readonly fullName: string | null;
      };
      readonly id: string;
      readonly lastSync: string | null;
      readonly name: string;
      readonly status: StatusIntegrationTypeEnum;
      readonly system: {
        readonly category: string;
        readonly name: string;
        readonly slug: string;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly value: string;
    }>;
  } | null;
};
export type stripeConnectionModalCreateStripeIntegrationMutation = {
  response: stripeConnectionModalCreateStripeIntegrationMutation$data;
  variables: stripeConnectionModalCreateStripeIntegrationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSync",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ChargebeeError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "stripeConnectionModalCreateStripeIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateStripeIntegrationPayload",
        "kind": "LinkedField",
        "name": "createStripeIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemIntegration",
            "kind": "LinkedField",
            "name": "connection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "System",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "stripeConnectionModalCreateStripeIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateStripeIntegrationPayload",
        "kind": "LinkedField",
        "name": "createStripeIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemIntegration",
            "kind": "LinkedField",
            "name": "connection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "System",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "connection",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "SystemIntegration"
              }
            ]
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79aa0ba23ade66d7550cb648f3787adc",
    "id": null,
    "metadata": {},
    "name": "stripeConnectionModalCreateStripeIntegrationMutation",
    "operationKind": "mutation",
    "text": "mutation stripeConnectionModalCreateStripeIntegrationMutation(\n  $input: CreateStripeIntegrationInput!\n) {\n  createStripeIntegration(input: $input) {\n    connection {\n      name\n      id\n      lastSync\n      system {\n        category\n        name\n        slug\n        id\n      }\n      creator {\n        fullName\n        id\n      }\n      status\n    }\n    errors {\n      key\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb342bd579cd7ccca02dc1d674adc53e";

export default node;
