import { useUserStore } from "@shared/store"
import { ReactElement, cloneElement, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"

type Props = {
  element: ReactElement
}

export const ClientRoute = ({ element }: Props) => {
  const {
    currentClient: { id: currentClientId },
  } = useUserStore()
  const navigate = useNavigate()
  const location = useLocation()
  const initialClientIdRef = useRef<string | null>(null)

  useEffect(() => {
    if (initialClientIdRef.current === null) {
      initialClientIdRef.current = currentClientId
    } else if (currentClientId !== initialClientIdRef.current) {
      // If we're on an account-map detail page, redirect to the list
      if (location.pathname.includes('/account-map/')) {
        navigate("/account-maps")
      }
    }
  }, [currentClientId, location.pathname, navigate])

  if (currentClientId) {
    return cloneElement(element, { key: currentClientId })
  } else {
    navigate("/portfolio", { replace: true })
    return null
  }
}
