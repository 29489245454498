/**
 * @generated SignedSource<<ef6472257aca12f732864b17a3c089bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateMapGroupAccountMutationInput = {
  clientMutationId?: string | null;
  connectionId: string;
  mapGroupId: string;
  mapId: string;
  systemAccountId: string;
};
export type accountMapCreateMapGroupAccountMutation$variables = {
  input: CreateMapGroupAccountMutationInput;
};
export type accountMapCreateMapGroupAccountMutation$data = {
  readonly createMapGroupAccount: {
    readonly mapGroupAccount: {
      readonly account: {
        readonly id: string;
        readonly name: string | null;
        readonly number: string | null;
      };
      readonly mapGroup: {
        readonly id: string;
        readonly name: string | null;
      };
    } | null;
  } | null;
};
export type accountMapCreateMapGroupAccountMutation = {
  response: accountMapCreateMapGroupAccountMutation$data;
  variables: accountMapCreateMapGroupAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "MapGroup",
  "kind": "LinkedField",
  "name": "mapGroup",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountMapCreateMapGroupAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMapGroupAccountMutationPayload",
        "kind": "LinkedField",
        "name": "createMapGroupAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MapGroupAccount",
            "kind": "LinkedField",
            "name": "mapGroupAccount",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountMapCreateMapGroupAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMapGroupAccountMutationPayload",
        "kind": "LinkedField",
        "name": "createMapGroupAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MapGroupAccount",
            "kind": "LinkedField",
            "name": "mapGroupAccount",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78131c2543c286cc59af19ce79fdb9cb",
    "id": null,
    "metadata": {},
    "name": "accountMapCreateMapGroupAccountMutation",
    "operationKind": "mutation",
    "text": "mutation accountMapCreateMapGroupAccountMutation(\n  $input: CreateMapGroupAccountMutationInput!\n) {\n  createMapGroupAccount(input: $input) {\n    mapGroupAccount {\n      account {\n        id\n        number\n        name\n      }\n      mapGroup {\n        id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64975afacd799580dcef0f91d9cb7d96";

export default node;
