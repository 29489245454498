/**
 * @generated SignedSource<<9b8814f48093a924459b213a52eccbaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type mapsDrawerGetIntegrationsQuery$variables = {
  systemType?: string | null;
};
export type mapsDrawerGetIntegrationsQuery$data = {
  readonly getIntegrations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly system: {
          readonly category: string;
        };
      };
    }>;
  };
};
export type mapsDrawerGetIntegrationsQuery = {
  response: mapsDrawerGetIntegrationsQuery$data;
  variables: mapsDrawerGetIntegrationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "systemType",
    "variableName": "systemType"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mapsDrawerGetIntegrationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SystemIntegrationConnection",
        "kind": "LinkedField",
        "name": "getIntegrations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemIntegrationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SystemIntegration",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "System",
                    "kind": "LinkedField",
                    "name": "system",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mapsDrawerGetIntegrationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SystemIntegrationConnection",
        "kind": "LinkedField",
        "name": "getIntegrations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemIntegrationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SystemIntegration",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "System",
                    "kind": "LinkedField",
                    "name": "system",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ada54ddb7ecc53968914d12c8911a32e",
    "id": null,
    "metadata": {},
    "name": "mapsDrawerGetIntegrationsQuery",
    "operationKind": "query",
    "text": "query mapsDrawerGetIntegrationsQuery(\n  $systemType: String\n) {\n  getIntegrations(systemType: $systemType) {\n    edges {\n      node {\n        id\n        name\n        system {\n          category\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cba7b4d42e7960193ae5b12c563feca2";

export default node;
