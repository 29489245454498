import { plaidLinkQuery } from "./__generated__/plaidLinkQuery.graphql"
import styles from "./plaid-link.module.scss"
import {
  Box,
  Group,
  Input,
  LoadingOverlay,
  Space,
  Text,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { IconSearch } from "@tabler/icons-react"
import { Suspense, useEffect, useState } from "react"
import { graphql, useLazyLoadQuery, useQueryLoader } from "react-relay"
import { useSearchParams } from "react-router-dom"
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from 'react-plaid-link';
import { PlaidLinkContent } from './plaid-link-content'

export const PlaidLinkQuery = graphql`
  query plaidLinkQuery($companyId: String!) {
    getPlaidLinkToken(companyId: $companyId) {
      linkToken
    }
  }
`
type PlaidLinkProps = {
  onSuccess?: () => void
}


export const PlaidLink = ({ onSuccess }: PlaidLinkProps) => {
  const {
    currentClient: { id: currentClientId },
  } = useUserStore()

  const [queryRef, loadQuery] = useQueryLoader(PlaidLinkQuery)

  useEffect(() => {
    loadQuery({ companyId: currentClientId })
  }, [currentClientId, loadQuery])

  if (!queryRef) {
    return <LoadingOverlay visible={true} />
  }

  return (
    <Suspense fallback={<LoadingOverlay visible={true} />}>
      <PlaidLinkContent queryRef={queryRef} onSuccess={onSuccess} />
    </Suspense>
  )
}
