/**
 * @generated SignedSource<<b25ce9dd129a8811f8dbdaa44a6478c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DisplayEnum = "CLASS" | "DEPARTMENT" | "ENTITY" | "MONTH" | "QUARTER" | "YEAR";
export type ReportTypeEnum = "BALANCE" | "CASH" | "INCOME";
export type StatementNumberFormatEnum = "DIVIDE_1000" | "EXCEPT_ZERO" | "SHOW_RED" | "WITHOUT_CENTS";
export type StatementRepresentationByEnum = "AMOUNT" | "ANNUALIZED" | "MOM_CHANGE" | "PERCENTAGE" | "TWELVE_MONTH";
export type UpsertSavedStatementMutationInput = {
  clientMutationId?: string | null;
  displayBy: DisplayEnum;
  endDate: string;
  id?: string | null;
  name: string;
  numberFormat?: ReadonlyArray<StatementNumberFormatEnum> | null;
  reportType: ReportTypeEnum;
  representationBy?: StatementRepresentationByEnum | null;
  showZeroBalance: boolean;
  startDate: string;
  systemConnectionIds: ReadonlyArray<string>;
};
export type saveStatementsModalUpsertSavedStatementMutation$variables = {
  input: UpsertSavedStatementMutationInput;
};
export type saveStatementsModalUpsertSavedStatementMutation$data = {
  readonly upsertSavedStatement: {
    readonly savedStatement: {
      readonly data: {
        readonly displayBy: DisplayEnum | null;
        readonly endDate: string | null;
        readonly entities: ReadonlyArray<{
          readonly connection: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly isDeleted: boolean | null;
        }> | null;
        readonly numberFormat: ReadonlyArray<StatementNumberFormatEnum> | null;
        readonly reportType: ReportTypeEnum | null;
        readonly representationBy: StatementRepresentationByEnum | null;
        readonly showZeroBalance: boolean | null;
        readonly startDate: string | null;
      } | null;
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};
export type saveStatementsModalUpsertSavedStatementMutation = {
  response: saveStatementsModalUpsertSavedStatementMutation$data;
  variables: saveStatementsModalUpsertSavedStatementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertSavedStatementMutationPayload",
    "kind": "LinkedField",
    "name": "upsertSavedStatement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SavedStatement",
        "kind": "LinkedField",
        "name": "savedStatement",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedStatementData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reportType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedStatementEntity",
                "kind": "LinkedField",
                "name": "entities",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SystemIntegration",
                    "kind": "LinkedField",
                    "name": "connection",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDeleted",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "representationBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberFormat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showZeroBalance",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveStatementsModalUpsertSavedStatementMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveStatementsModalUpsertSavedStatementMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "787476317f064b49ef3657eb7dcd605c",
    "id": null,
    "metadata": {},
    "name": "saveStatementsModalUpsertSavedStatementMutation",
    "operationKind": "mutation",
    "text": "mutation saveStatementsModalUpsertSavedStatementMutation(\n  $input: UpsertSavedStatementMutationInput!\n) {\n  upsertSavedStatement(input: $input) {\n    savedStatement {\n      id\n      name\n      data {\n        reportType\n        entities {\n          connection {\n            id\n            name\n          }\n          isDeleted\n        }\n        startDate\n        endDate\n        displayBy\n        representationBy\n        numberFormat\n        showZeroBalance\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0bd0277f3f24cfbeb8a67682e2be87d0";

export default node;
