import { statementsConnectionsQuery as StatementsConnectionsQueryType } from "./__generated__/statementsConnectionsQuery.graphql"
import { statementsGetSavedStatementQuery as StatementsGetSavedStatementQueryType } from "./__generated__/statementsGetSavedStatementQuery.graphql"
import { statementsClassesQuery as StatementsClassesQueryType } from "./__generated__/statementsClassesQuery.graphql"
import { EmptyState } from "./empty-state"
import { EntitiesComponent } from "./entities-component/entities-component"
import { ReportPeriodSelector } from "./report-period-selector/report-period-selector"
import { ReportTable } from "./report-table"
import {
  DisplayEnum,
  reportTableGetFinancialStatementQuery as ReportTableGetFinancialStatementQueryType,
  ReportTypeEnum,
} from "./report-table/__generated__/reportTableGetFinancialStatementQuery.graphql"
import { ReportTableGetFinancialStatementQuery } from "./report-table/report-table"
import { SaveStatementsButton } from "./save-statements-button"
import styles from "./statements.module.scss"
import { TableLoader } from "./table-loader"
import { NumberFormatEnum } from "./utils/format"
import {
  Box,
  Button,
  Card,
  Divider,
  Group,
  Select,
  Space,
  Stack,
  Text,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { MultiSelect } from "@shared/ui/multiselect"
import { convertDateToISOString } from "@shared/utils/helpers"
import { IconChevronDown, IconSlash } from "@tabler/icons-react"
import {
  addMinutes,
  endOfDay,
  endOfMonth,
  startOfYear,
  subMonths,
} from "date-fns"
import { pathConstants } from "frontend/routes/path-constants"
import { debounce, isEqual } from "lodash"
import { Suspense, useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { graphql, useLazyLoadQuery, useQueryLoader } from "react-relay"
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom"
import { ClassesComponent } from "./classes-component/classes-component"

const StatementsConnectionsQuery = graphql`
  query statementsConnectionsQuery($systemType: String) {
    getIntegrations(systemType: $systemType) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const StatementsClassesQuery = graphql`
  query statementsClassesQuery($connectionIds: [ID!]!) {
    getClasses(connectionIds: $connectionIds) {
      edges {
        node {
          id
          name
          systemClassId
        }
      }
    }
  }
`

const StatementsGetSavedStatementQuery = graphql`
  query statementsGetSavedStatementQuery($id: ID!, $skip: Boolean!) {
    getSavedStatement(id: $id) @skip(if: $skip) {
      id
      name
      data {
        reportType
        entities {
          connection {
            id
            name
          }
          isDeleted
        }
        startDate
        endDate
        displayBy
        representationBy
        numberFormat
        showZeroBalance
        classes {
          id
          name
        }
      }
    }
  }
`

export enum RepresentationEnum {
  AMOUNT = "amount",
  PERCENTAGE = "percentage",
  TWELVE_MONTH = "twelve_months",
  ANNUALIZED = "annualized",
  MOM = "mom_change"
}

export type FormType = {
  statementName: string
  reportType: ReportTypeEnum
  systemConnectionIds: string[]
  dates: {
    startDate: string
    endDate: string
  }
  display: DisplayEnum
  representationBy: RepresentationEnum
  numberFormat: string[]
  zeroBalance: boolean
  classIds: string[]
}

export const Statements = () => {
  const { id } = useParams()
  const location = useLocation()
  const { currentClient, lastUpdate, setCashFlowPath } = useUserStore()
  const [searchParams, setSearchParams] = useSearchParams()

  const paramSystemConnectionIds = searchParams.getAll("systemConnectionIds")
  const paramStartDate = searchParams.get("startDate")
  const paramEndDate = searchParams.get("endDate")
  const paramDisplay = searchParams.get("display") as DisplayEnum
  const paramReportType = searchParams.get("reportType") as ReportTypeEnum
  const paramRepresentationBy = searchParams.get(
    "representationBy"
  ) as RepresentationEnum
  const paramNumberFormat = searchParams.getAll("numberFormat")
  const paramZeroBalance = searchParams.get("zeroBalance")

  const isSavedStatement = !!id

  const { getIntegrations } = useLazyLoadQuery<StatementsConnectionsQueryType>(
    StatementsConnectionsQuery,
    { systemType: "ledger" },
    {
      fetchKey: [currentClient.id, lastUpdate].join(""),
      fetchPolicy: "network-only",
    }
  )

  const connections = useMemo(
    () =>
      getIntegrations.edges.map((integration) => {
        return {
          key: integration.node.id,
          label: integration.node.name,
          isDeleted: false,
        }
      }),
    [getIntegrations.edges]
  )

  const connectionsIds = useMemo(
    () => connections.map((val) => val.key),
    [connections]
  )

  const { getClasses } = useLazyLoadQuery<StatementsClassesQueryType>(
    StatementsClassesQuery,
    { connectionIds: connectionsIds },
    {
      fetchKey: [currentClient.id, lastUpdate].join(""),
      fetchPolicy: "network-only",
    }
  )

  const { getSavedStatement } =
    useLazyLoadQuery<StatementsGetSavedStatementQueryType>(
      StatementsGetSavedStatementQuery,
      { id: id ?? "", skip: !isSavedStatement }
    )

  const savedStatementData = getSavedStatement?.data

  const savedStatementEntities = useMemo(
    () =>
      savedStatementData?.entities?.map((entity) => ({
        key: entity.connection?.id ?? "",
        label: entity.connection?.name ?? "",
        isDeleted: entity.isDeleted ?? true,
      })),
    [savedStatementData?.entities]
  )

  const savedDeletedEntities = useMemo(
    () => savedStatementEntities?.filter((val) => val.isDeleted),
    [savedStatementEntities]
  )

  const allEntities = useMemo(
    () =>
      savedDeletedEntities
        ? [...connections, ...savedDeletedEntities]
        : connections,
    [connections, savedDeletedEntities]
  )

  const savedEntitiesIds = useMemo(
    () =>
      savedStatementEntities
        ?.filter((val) => !val.isDeleted)
        .map((val) => val.key),
    [savedStatementEntities]
  )

  const hasConnections = allEntities.length > 0

  const classOptions = useMemo(
    () => [
      { key: "NO_CLASS", label: "No Class", isDeleted: false, systemClassId: "NO_CLASS" },
      ...getClasses.edges.map((classItem) => ({
        key: classItem.node.id,
        label: classItem.node.name,
        isDeleted: false,
        systemClassId: classItem.node.systemClassId
      })),
    ],
    [getClasses.edges]
  )

  const classIds = useMemo(
    () => classOptions.map((val) => val.key),
    [classOptions]
  )

  const reportOptions = [
    { value: "INCOME", label: "Income Statement" },
    { value: "BALANCE", label: "Balance Sheet" },
    { value: "CASH", label: "Cash Flow Statement" },
  ]

  const representationOptions = [
    { label: "Basic", value: RepresentationEnum.AMOUNT },
    { label: "Ratio", value: RepresentationEnum.PERCENTAGE },
    { label: "Trailing Twelve Months", value: RepresentationEnum.TWELVE_MONTH },
    { label: "Annualized", value: RepresentationEnum.ANNUALIZED },
    { label: "Monthly Change", value: RepresentationEnum.MOM }
  ]

  const [tableQueryRef, loadTableQueryRef] =
    useQueryLoader<ReportTableGetFinancialStatementQueryType>(
      ReportTableGetFinancialStatementQuery
    )

  const onSubmit = debounce((params: FormType) => {
    const cleanedFilters = cleanFilters(params)
    setSearchParams((prev) => ({ ...prev, ...cleanedFilters }), {
      replace: true,
    })

    const allClassesSelected = params.classIds.length === classIds.length

    const selectedSystemClassIds = allClassesSelected
      ? null
      : params.classIds.map(classId =>
        classOptions.find(opt => opt.key === classId)?.systemClassId
      ).filter(Boolean) as string[]

    const queryParams = {
      clientId: currentClient.id,
      ...params,
      ...params.dates,
      classIds: selectedSystemClassIds,
    }

    console.log(selectedSystemClassIds)

    loadTableQueryRef(queryParams)
  })

  const setZeroBalance = (value: boolean) => {
    setValue("zeroBalance", value, { shouldDirty: true })
  }

  const today = new Date()
  const defaultStartDate = convertDateToISOString(startOfYear(today))
  const defaultEndDate = convertDateToISOString(endOfMonth(subMonths(today, 1)))

  const formInitialValues: FormType = {
    statementName: getSavedStatement?.name ?? "",
    systemConnectionIds: savedEntitiesIds ?? connectionsIds,
    dates: {
      startDate: savedStatementData?.startDate
        ? convertDateToISOString(new Date(savedStatementData?.startDate))
        : defaultStartDate,
      endDate: savedStatementData?.endDate
        ? convertDateToISOString(new Date(savedStatementData?.endDate))
        : defaultEndDate,
    },
    display: savedStatementData?.displayBy ?? "MONTH",
    reportType: savedStatementData?.reportType ?? "INCOME",
    representationBy:
      RepresentationEnum[savedStatementData?.representationBy ?? "AMOUNT"],
    numberFormat: savedStatementData?.numberFormat?.map((val) => val) ?? [
      "WITHOUT_CENTS",
      "SHOW_RED",
    ],
    zeroBalance: savedStatementData?.showZeroBalance ?? false,
    classIds: savedStatementData?.classIds ?? classIds,
  }

  const {
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<FormType>({
    defaultValues: {
      statementName: getSavedStatement?.name ?? "",
      systemConnectionIds:
        savedEntitiesIds ??
        (paramSystemConnectionIds.length > 0
          ? paramSystemConnectionIds
          : connectionsIds),
      dates: {
        startDate: savedStatementData?.startDate
          ? convertDateToISOString(new Date(savedStatementData?.startDate))
          : paramStartDate ?? defaultStartDate,
        endDate: savedStatementData?.endDate
          ? convertDateToISOString(new Date(savedStatementData?.endDate))
          : paramEndDate ?? defaultEndDate,
      },
      display: savedStatementData?.displayBy ?? paramDisplay ?? "MONTH",
      reportType: savedStatementData?.reportType ?? paramReportType ?? "INCOME",
      representationBy: savedStatementData?.representationBy
        ? RepresentationEnum[savedStatementData?.representationBy]
        : paramRepresentationBy ?? RepresentationEnum.AMOUNT,
      numberFormat:
        savedStatementData?.numberFormat?.map((val) => val) ??
        (paramNumberFormat.length > 0
          ? paramNumberFormat
          : ["WITHOUT_CENTS", "SHOW_RED"]),
      zeroBalance:
        savedStatementData?.showZeroBalance ?? paramZeroBalance == "true"
          ? true
          : false,
      classIds: savedStatementData?.classIds ?? classIds,
    },
  })


  const displayOptions = useMemo(() => {
    const options = [
      { value: "ENTITY", label: "Entities" },
      { value: "MONTH", label: "Months" },
      { value: "QUARTER", label: "Quarters" },
      { value: "YEAR", label: "Years" },
    ]

    if (getValues().reportType === "INCOME") {
      options.push({ value: "DEPARTMENT", label: "Departments" })
      options.push({ value: "CLASS", label: "Classes" })
    }

    return options
  }, [getValues, getValues().reportType])




  const cleanFilters = (params: FormType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cleanedFilters: Record<string, any> = {}
    Object.keys(params).forEach((key) => {
      const formKey = key as keyof FormType
      if (formKey == "statementName") return
      if (formKey == "dates" && params.dates !== null) {
        if (!isEqual(formInitialValues.dates, params.dates)) {
          cleanedFilters["startDate"] = params.dates.startDate
          cleanedFilters["endDate"] = params.dates.endDate
        }
        return
      }

      if (
        !isEqual(formInitialValues[formKey], params[formKey]) &&
        params[formKey] !== null
      ) {
        cleanedFilters[formKey] = params[formKey]
      }
    })

    return cleanedFilters
  }

  const reportTypeLabel = reportOptions.find(
    (option) => option.value == getValues().reportType
  )

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch, currentClient])

  useEffect(() => {
    setValue(
      "systemConnectionIds",
      savedEntitiesIds ??
      (paramSystemConnectionIds.length > 0
        ? paramSystemConnectionIds
        : connectionsIds)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedEntitiesIds, connectionsIds])

  if (!hasConnections) {
    return <EmptyState pageTitle="Statements" />
  }

  const configurationButton = (
    <>
      {getValues().reportType === "CASH" && (
        <Button
          variant={"outline"}
          color="blue"
          component={Link}
          to={pathConstants.CASH_FLOW_CONFIGURATION}
          onClick={() => {
            setCashFlowPath(`${location.pathname}${location.search}`)
          }}
        >
          Configuration
        </Button>
      )}
    </>
  )

  const headerComponent = id ? (
    <Group justify="space-between">
      <Group gap={8}>
        <Text
          size="xxl"
          fw={700}
          c={"gray"}
          component={Link}
          to={pathConstants.SAVED_REPORTS}
        >
          Saved Reports
        </Text>
        <IconSlash style={{ color: themeVars.colors.gray[6] }} />
        <Controller
          name="statementName"
          control={control}
          render={({ field: { onChange } }) => (
            <Text
              contentEditable
              onInput={(val) => onChange(val.currentTarget.textContent)}
              fw={500}
              size="lg"
              c="gray.6"
              maw={rem(getValues().reportType === "CASH" ? 360 : 480)}
            >
              {getSavedStatement?.name ?? ""}
            </Text>
          )}
        />
        <Divider
          orientation="vertical"
          style={{ borderColor: themeVars.colors.gray[3] }}
        />
        <Controller
          control={control}
          name="reportType"
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                rightSection={<IconChevronDown size={16} />}
                value={value ?? []}
                data={reportOptions}
                allowDeselect={false}
                withCheckIcon={false}
                onChange={(val) => {
                  const options =
                    val == "INCOME"
                      ? ["WITHOUT_CENTS", "SHOW_RED"]
                      : ["EXCEPT_ZERO", "WITHOUT_CENTS", "SHOW_RED"]
                  !id && setValue("numberFormat", options)
                  onChange(val)
                }}
              />
            )
          }}
        />
      </Group>
      <Group>
        {configurationButton}
        <SaveStatementsButton
          showZeroBalance={getValues().zeroBalance}
          representation={getValues().representationBy}
          id={id}
          {...getValues()}
          {...getValues().dates}
          disabled={!isDirty}
          resetFields={(params: FormType) => {
            reset(params)
          }}
        />
      </Group>
    </Group>
  ) : (
    <Group justify="space-between">
      <Group>
        <Text size="xxl" fw={700} c={"gray"} component="h1">
          Statement
        </Text>
        <Divider
          orientation="vertical"
          style={{ borderColor: themeVars.colors.gray[3] }}
        />
        <Controller
          control={control}
          name="reportType"
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                rightSection={<IconChevronDown size={16} />}
                value={value ?? []}
                data={reportOptions}
                allowDeselect={false}
                withCheckIcon={false}
                onChange={(val) => {
                  const options =
                    val == "INCOME"
                      ? ["WITHOUT_CENTS", "SHOW_RED"]
                      : ["EXCEPT_ZERO", "WITHOUT_CENTS", "SHOW_RED"]
                  !id && setValue("numberFormat", options)
                  onChange(val)
                }}
              />
            )
          }}
        />
      </Group>
      {configurationButton}
    </Group>
  )

  return (
    <Box size="xl" p={rem(32)}>
      {headerComponent}
      <Space h={rem(32)} />
      <Stack gap={rem(32)}>
        <Stack gap={rem(32)}>
          <Text fw="bold" c="gray.7">
            Customize Your View
          </Text>
          <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
            <Group gap={rem(16)} grow>
              <Stack>
                <Text size="sm" fw="bold" c="dark.6">
                  Entities
                </Text>
                <Controller
                  control={control}
                  name="systemConnectionIds"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <EntitiesComponent
                        options={allEntities}
                        values={value}
                        allCheckedLabel="All Entities"
                        onChange={onChange}
                      />
                    )
                  }}
                />
              </Stack>
              <Stack>
                <Text size="sm" fw="bold" c="dark.6">
                  Report Period
                </Text>
                <Controller
                  control={control}
                  name="dates"
                  render={({ field: { onChange } }) => {
                    const formattedStartDate =
                      paramStartDate &&
                      addMinutes(
                        new Date(paramStartDate),
                        today.getTimezoneOffset()
                      )
                    const formattedEndDate =
                      paramEndDate &&
                      endOfDay(
                        addMinutes(
                          new Date(paramEndDate),
                          today.getTimezoneOffset()
                        )
                      )

                    return (
                      <ReportPeriodSelector
                        onChange={onChange}
                        defaultDates={
                          id
                            ? {
                              startDate: savedStatementData?.startDate
                                ? new Date(savedStatementData?.startDate)
                                : null,
                              endDate: savedStatementData?.endDate
                                ? new Date(savedStatementData?.endDate)
                                : null,
                            }
                            : formattedStartDate && formattedEndDate
                              ? {
                                startDate: formattedStartDate,
                                endDate: formattedEndDate,
                              }
                              : null
                        }
                      />
                    )
                  }}
                />
              </Stack>
              <Stack>
                <Text size="sm" fw="bold" c="dark.6">
                  Display Column by
                </Text>
                <Controller
                  control={control}
                  name="display"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        data={displayOptions}
                        withCheckIcon={false}
                        allowDeselect={false}
                        rightSection={<IconChevronDown size={16} />}
                        onChange={onChange}
                        classNames={{ option: styles.Option }}
                      />
                    )
                  }}
                />
              </Stack>
              {getValues().reportType === "INCOME" && (
                <Stack>
                  <Text size="sm" fw="bold" c="dark.6">
                    Representation
                  </Text>
                  <Controller
                    control={control}
                    name="representationBy"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          data={representationOptions}
                          value={value}
                          onChange={onChange}
                          withCheckIcon={false}
                          allowDeselect={false}
                          rightSection={<IconChevronDown size={16} />}
                          classNames={{ option: styles.Option }}
                        />
                      )
                    }}
                  ></Controller>
                </Stack>
              )}
              <Stack>
                <Text size="sm" fw="bold" c="dark.6">
                  Number Format
                </Text>
                <Controller
                  name="numberFormat"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultiSelect
                      data={[
                        {
                          label: "Except zero amount",
                          value: NumberFormatEnum.EXCEPT_ZERO,
                        },
                        {
                          label: "Without cents",
                          value: NumberFormatEnum.WITHOUT_CENTS,
                        },
                        {
                          label: "Divide by 1000",
                          value: NumberFormatEnum.DIVIDE_1000,
                        },
                        {
                          label: "Show negatives in red",
                          value: NumberFormatEnum.SHOW_RED,
                        },
                      ]}
                      defaultValue={value}
                      onChange={onChange}
                      placeholder="Pick one or more styles"
                    />
                  )}
                />
              </Stack>
              <Stack>
                <Text size="sm" fw="bold" c="dark.6">
                  Filter by Class
                </Text>
                <Controller
                  control={control}
                  name="classIds"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ClassesComponent
                        options={classOptions}
                        values={value}
                        allCheckedLabel="All Classes"
                        onChange={onChange}
                      />
                    )
                  }}
                />
              </Stack>
            </Group>
          </Card>
        </Stack>
        <Suspense fallback={<TableLoader />}>
          {tableQueryRef && (
            <ReportTable
              id={id}
              reportTypeLabel={reportTypeLabel?.label ?? ""}
              {...getValues()}
              {...getValues().dates}
              clientId={currentClient.id}
              queryRef={tableQueryRef}
              formatFilters={getValues().numberFormat}
              clientName={currentClient.name}
              setFormValueZeroBalance={setZeroBalance}
            />
          )}
        </Suspense>
      </Stack>
    </Box>
  )
}
