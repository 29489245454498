import { useUserStore } from "@shared/store"
import { useDisclosure } from "@mantine/hooks"
import styles from "./styles.module.scss"
import { Box, Button, Group, Space, Stack, Text } from "@mantine/core"
import { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useParams } from "react-router-dom"
import Logo from "@shared/assets/logo.svg"

const BASE_URL = import.meta.env.VITE_METABASE_EMBEDDING_BASE_URL
const BASE_UI_ATTRIBUTES = encodeURIComponent(
  "top_nav=false&side_nav=false&header=false&action_buttons=false"
)


export const Budget = () => {

  const { isAdmin, currentClient, metabaseToken } = useUserStore()
  const [opened, { open, close }] = useDisclosure(false)
  const [frameLoaded, setFrameLoaded] = useState(false)

  const dashboardId = "1820"


  const iframeURL = `${BASE_URL}/auth/sso?jwt=${metabaseToken}&return_to=/dashboard/${dashboardId}?${BASE_UI_ATTRIBUTES}`


  return (
   <Box className={styles.SharedLayout}>    
      <Group justify="space-between" className={styles.SharedLayout__TopBar}>



      </Group>
      <Box>
        {frameLoaded && (
          <Text
            size="xl"
            fw={700}
            c="gray"
            className={styles.SharedLayout__TitleBar}
          >
            Performance to Budget
          </Text>
        )}
      </Box>

      {metabaseToken ? (
        <iframe
          src={iframeURL}
          width={"100%"}
          height={"100%"}
          loading="eager"
          className={styles.Iframe}
          onLoad={() => setFrameLoaded(true)}
        />
      ) : (
        <Stack justify="center" align="center" gap={0}>
          <Title c="blue" order={1}>
            404
          </Title>
          <Title c="gray" order={2}>
            Not found
          </Title>
        </Stack>
      )}
    </Box>

  )
}