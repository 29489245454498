import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Group,
  Modal,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
  Paper,
} from "@mantine/core"
import { IconTrash, IconArrowLeft } from '@tabler/icons-react'
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { useState, useMemo, DragEvent, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { formatPhoneNumberIntl } from "react-phone-number-input"
import { graphql, useLazyLoadQuery, useMutation } from "react-relay"
import { Link, useParams } from "react-router-dom"
import { accountMapDetailsQuery as accountMapDetailsQueryType } from "./__generated__/accountMapDetailsQuery.graphql"
import { accountMapUnmappedAccountsQuery as accountMapUnmappedAccountsQueryType } from "./__generated__/accountMapUnmappedAccountsQuery.graphql"
import { accountMapMapGroupsQuery as accountMapMapGroupsQueryType } from "./__generated__/accountMapMapGroupsQuery.graphql"
import { accountMapCreateMapGroupMutation as accountMapCreateMapGroupMutationType } from "./__generated__/accountMapCreateMapGroupMutation.graphql"
import { accountMapCreateMapGroupAccountMutation as accountMapCreateMapGroupAccountMutationType } from "./__generated__/accountMapCreateMapGroupAccountMutation.graphql"
import { accountMapDeleteMapGroupMutation as accountMapDeleteMapGroupMutationType } from "./__generated__/accountMapDeleteMapGroupMutation.graphql"
import { accountMapUpdateMapGroupMutation as accountMapUpdateMapGroupMutationType } from "./__generated__/accountMapUpdateMapGroupMutation.graphql"

const accountMapDetailsQuery = graphql`
  query accountMapDetailsQuery($input: ID!) {
    getMapDetails(mapId: $input) {
      id
      name
      creator {
        fullName
      }
      createdAt
      mapGroups {
        nodes {
          id
          name
        }
      }
      mapDepartments{
        nodes{
          id
          departmentName
        }
      }
    }
  }
`

const accountMapUnmappedAccountsQuery = graphql`
  query accountMapUnmappedAccountsQuery($input: ID!) {
    getUnmappedAccounts(mapId: $input) {
      accounts {
        account {
          systemAccountId
          number
          name
          accountType
          integration {
            id
            name
          }
        }
      }
    }
  }
`
const accountMapMapGroupsQuery = graphql`
  query accountMapMapGroupsQuery($input: ID!) {
    getMapGroups(mapId: $input) {
      count
      edges{
        node{
          id
          name
          accountType
          unmapped
           mapDepartment{
            id
            departmentName
          }
          accounts{
            id
            systemAccountId
            number
            name
            accountType
            integration{
              id
              name
            }
          }
        }
      }
    }
  }
`

const accountMapCreateMapGroupMutation = graphql`
  mutation accountMapCreateMapGroupMutation($input: CreateMapGroupMutationInput!) {
    createMapGroupMutation(input: $input) {
      mapGroup {
        id
        name
      }
    }
  }
`

const accountMapCreateMapGroupAccountMutation = graphql`
  mutation accountMapCreateMapGroupAccountMutation($input: CreateMapGroupAccountMutationInput!) {
    createMapGroupAccount(input: $input) {
      mapGroupAccount {
         account{
        id
        number
        name
      }
      mapGroup{
        id
        name
      }
      }
    }
  }
`

const accountMapDeleteMapGroupMutation = graphql`
  mutation accountMapDeleteMapGroupMutation($input: DeleteMapGroupMutationInput!) {
    deleteMapGroup(input: $input) {
      mapGroup {
        id
      }
    }
  }
`

const accountMapUpdateMapGroupMutation = graphql`
  mutation accountMapUpdateMapGroupMutation($input: UpdateMapGroupMutationInput!) {
    updateMapGroupMutation(input: $input) {
      mapGroup {
        id
        name
        mapDepartment {
          id
          departmentName
        }
      }
    }
  }
`;

const GL_ACCOUNT_TYPES = [
  'Bank',
  'Accounts Receivable',
  'Inventory',
  'Other Current Asset',
  'Fixed Asset',
  'Other Asset',
  'Accounts Payable',
  'Credit Card',
  'Other Current Liability',
  'Long Term Liability',
  'Equity',
  'Income',
  'Cost of Goods Sold',
  'Expense',
  'Other Income',
  "Other Expense",
]

interface AccountGroup {
  id: string;
  name: string | null;
  type: string | null;
  department?: string | undefined;
  departmentId?: string | null | undefined;
  accounts: Array<{
    id: string;
    systemAccountId: string | null;
    number: string | null;
    name: string | null;
    type: string | null;
    connectionId: string | null;
  }>;
}

export const AccountMap = () => {
  const { currentClient: { id: currentClientId } } = useUserStore()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (!id) {
      navigate("/account-maps")
    }
  }, [id, navigate])

  const [editingGroupId, setEditingGroupId] = useState<string | null>(null);
  const [editingGroupName, setEditingGroupName] = useState("");
  const [newGroupName, setNewGroupName] = useState('');

  const [createMapGroup] = useMutation<accountMapCreateMapGroupMutationType>(accountMapCreateMapGroupMutation);
  const [createMapGroupAccount] = useMutation<accountMapCreateMapGroupAccountMutationType>(accountMapCreateMapGroupAccountMutation);

  const formatAccountName = (account: { number: string | null, name: string }) => {
    return account.number ? `${account.number}: ${account.name}` : account.name;
  };
  const [opened, { open, close }] = useDisclosure(false);
  const { getMapDetails } = useLazyLoadQuery<accountMapDetailsQueryType>(accountMapDetailsQuery, { input: id })
  const { getUnmappedAccounts } = useLazyLoadQuery<accountMapUnmappedAccountsQueryType>(accountMapUnmappedAccountsQuery, { input: id })
  const { getMapGroups } = useLazyLoadQuery<accountMapMapGroupsQueryType>(accountMapMapGroupsQuery, { input: id })
  const [deleteMapGroup] = useMutation<accountMapDeleteMapGroupMutationType>(accountMapDeleteMapGroupMutation);
  const [updateMapGroup] = useMutation(accountMapUpdateMapGroupMutation);

  const [accounts, setAccounts] = useState(
    getUnmappedAccounts.accounts.map(({ account }) => ({
      id: account.systemAccountId,
      number: account.number,
      name: account.name,
      type: account.accountType,
      connectionId: account.integration.id
    }))
  );

  const [groups, setGroups] = useState<AccountGroup[]>(
    getMapGroups.edges
      .filter(({ node }) => !node.unmapped)
      .map(({ node }) => ({
        id: node.id,
        name: node.name,
        type: node.accountType,
        department: node.mapDepartment?.departmentName || undefined,
        departmentId: node.mapDepartment?.id || undefined,
        accounts: node.accounts.map(account => ({
          id: account.id,
          systemAccountId: account.systemAccountId,
          number: account.number,
          name: account.name,
          type: account.accountType,
          connectionId: account.integration.id
        }))
      }))
  );

  const [draggedAccount, setDraggedAccount] = useState(null);

  const groupsByType = useMemo(() => {
    const relevantTypes = draggedAccount && !draggedAccount.previousGroupId
      ? [draggedAccount.type]
      : GL_ACCOUNT_TYPES;

    return relevantTypes.reduce<Record<string, AccountGroup[]>>((acc, type) => {
      acc[type] = groups.filter(group => group.type === type);
      return acc;
    }, {});
  }, [groups, draggedAccount]);

  const filteredAccounts = useMemo(() => {
    console.log('Filtering accounts:', accounts); // Debug log
    return accounts.filter(account => {
      const isInGroup = groups.some(group =>
        group.accounts.some(groupAccount => {
          const accountId = account.id;
          const groupAccountId = groupAccount.systemAccountId || groupAccount.id;
          return accountId === groupAccountId;
        })
      );
      return !isInGroup;
    });
  }, [accounts, groups]);

  const handleDragStart = (account, e: React.DragEvent) => {
    console.log('Starting drag with account:', account);

    setDraggedAccount(account);

    e.dataTransfer.effectAllowed = 'move';

  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = '#e6ffe6';
    e.currentTarget.style.borderColor = '#4CAF50';
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = 'white';
    e.currentTarget.style.borderColor = '#e6e6e6';
  };

  const handleDropOnNewGroupZone = (e) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = 'white';
    e.currentTarget.style.borderColor = '#ccc';
    if (!draggedAccount) return;
    open(); // Open the modal
  };

  const handleDragEnd = (e: DragEvent) => {
    if (e.dataTransfer?.dropEffect === 'none') {
      setDraggedAccount(null);
    }
  };

  const handleModalClose = () => {
    setDraggedAccount(null);
    close();
  };

  const handleGroupDragOver = (e: React.DragEvent, groupType: string) => {
    e.preventDefault();

    // If there's no dragged account or the types don't match
    if (!draggedAccount || draggedAccount.type !== groupType) {
      e.currentTarget.style.backgroundColor = '#ffe6e6'; // Light red
      e.currentTarget.style.borderColor = '#ff4d4d'; // Darker red
      e.dataTransfer.dropEffect = 'none';
      return;
    }

    e.currentTarget.style.backgroundColor = '#e6ffe6';
    e.currentTarget.style.borderColor = '#4CAF50';
    e.dataTransfer.dropEffect = 'move';
  };

  const handleGroupDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = 'white';
    e.currentTarget.style.borderColor = '#ccc';
  };

  const handleDropOnGroup = (e: React.DragEvent, groupId: string, groupType: string) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = 'white';
    e.currentTarget.style.borderColor = '#ccc';

    if (!draggedAccount) return;
    if (draggedAccount.type !== groupType) return;

    // Store the account type for scroll restoration
    const accountType = draggedAccount.type;

    const targetGroup = groups.find(g => g.id === groupId);
    if (!targetGroup) return;

    if (draggedAccount.previousGroupId === groupId) {
      setDraggedAccount(null);
      return;
    }

    const mapGroupAccountInput = {
      input: {
        mapId: id,
        mapGroupId: groupId,
        systemAccountId: draggedAccount.systemAccountId || draggedAccount.id,
        connectionId: draggedAccount.connectionId
      }
    };

    createMapGroupAccount({
      variables: mapGroupAccountInput,
      onError: (error) => {
        console.error('Error creating/updating group account:', error);
      },
      onCompleted: (response) => {
        // Update states as before
        setGroups(prevGroups => prevGroups.map(group => {
          if (group.id === groupId) {
            return {
              ...group,
              accounts: [...group.accounts, draggedAccount]
            };
          }
          if (group.id === draggedAccount.previousGroupId) {
            return {
              ...group,
              accounts: group.accounts.filter(account => account.id !== draggedAccount.id)
            };
          }
          return group;
        }));

        if (!draggedAccount.previousGroupId) {
          setAccounts(prevAccounts =>
            prevAccounts.filter(account => account.id !== draggedAccount.id)
          );
        }

        setDraggedAccount(null);

        // After state updates and filtering is removed, scroll to the relevant account type
        requestAnimationFrame(() => {
          const accountTypeElement = document.querySelector(`[data-account-type="${accountType}"]`);
          if (accountTypeElement) {
            accountTypeElement.scrollIntoView({ behavior: 'auto', block: 'start' });
          }
        });
      }
    });
  };

  const handleDeleteGroup = (groupId: string) => {
    if (!window.confirm('Are you sure you want to delete this group?')) {
      return;
    }

    const groupToDelete = groups.find(group => group.id === groupId);
    if (!groupToDelete) return;

    console.log('Accounts to be unassigned:', groupToDelete.accounts);

    deleteMapGroup({
      variables: {
        input: {
          mapGroupId: groupId
        }
      },
      onCompleted: (response) => {
        // Update local state to remove the deleted group
        setGroups(prevGroups => prevGroups.filter(group => group.id !== groupId));

        const unassignedAccounts = groupToDelete.accounts.map(account => ({
          id: account.systemAccountId || account.id,
          number: account.number,
          name: account.name,
          type: account.type,
          connectionId: account.connectionId
        }));

        setAccounts(prevAccounts => {
          const newAccounts = [...prevAccounts, ...unassignedAccounts];
          return newAccounts.sort((a, b) => {
            const typeA = GL_ACCOUNT_TYPES.indexOf(a.type);
            const typeB = GL_ACCOUNT_TYPES.indexOf(b.type);
            return typeA - typeB;
          });
        });
      },
      onError: (error) => {
        console.error('Error deleting group:', error);
        // You might want to show an error message to the user here
      }
    });
  };

  const handleGroupNameUpdate = (groupId: string, newName: string) => {
    if (newName.trim() === "") return;

    updateMapGroup({
      variables: {
        input: {
          mapGroupId: groupId,
          name: newName.trim()
        }
      },
      onCompleted: (response) => {
        setGroups(prevGroups =>
          prevGroups.map(group =>
            group.id === groupId
              ? { ...group, name: newName.trim() }
              : group
          )
        );
        setEditingGroupId(null);
      },
      onError: (error) => {
        console.error('Error updating group name:', error);
        setEditingGroupId(null);
      }
    });
  };

  const handleDepartmentChange = (groupId: string, departmentId: string | null) => {
    updateMapGroup({
      variables: {
        input: {
          mapGroupId: groupId,
          name: groups.find(g => g.id === groupId)?.name || '',
          newMapDepartmentId: departmentId
        }
      },
      onCompleted: (response) => {
        setGroups(prevGroups =>
          prevGroups.map(g =>
            g.id === groupId
              ? {
                ...g,
                departmentId,
                department: departmentId
                  ? getMapDetails?.mapDepartments?.nodes?.find(d => d.id === departmentId)?.departmentName
                  : undefined
              }
              : g
          )
        );
      },
      onError: (error) => {
        console.error('Error updating department:', error);
      }
    });
  };

  // Add department options from the query
  const departmentOptions = useMemo(() =>
    getMapDetails?.mapDepartments?.nodes?.map(dept => ({
      value: dept.id,
      label: dept.departmentName
    })) || []
    , [getMapDetails]);

  return (
    <Box p="xl">
      <Group mb="lg">
        <Button
          component={Link}
          to="/account-maps"
          variant="subtle"
          leftSection={<IconArrowLeft size={16} />}
        >
          Back to Account Maps
        </Button>
      </Group>
      <Text size="xxl" fw={700} c={"gray"} component="h1">
        Account Mapping - {getMapDetails?.name}
      </Text>
      <Space h={rem(56)} />
      <Grid>
        <Grid.Col span={4}>
          <Card
            shadow="xs"
            radius="md"
            p="1.5rem"
            style={{
              height: 'calc(100vh - 200px)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Title order={3}>Unassigned Accounts</Title>
            <Space h={rem(20)} />
            <Stack
              spacing={"xxs"}
              style={{
                overflowY: 'auto',
                flexGrow: 1
              }}
            >
              {filteredAccounts.map((account) => (
                <Paper
                  key={account.id}
                  draggable
                  onDragStart={(e) => handleDragStart(account, e)}
                  onDragEnd={handleDragEnd}
                  p="xs"
                  bg={themeVars.colors.gray[5]}
                  radius="sm"
                  fz="sm"
                  shadow="sm"
                  style={{ cursor: "move" }}
                >
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Text fw={700}>{formatAccountName(account)}</Text>
                    <Text fs="italic">{account.type}</Text>
                  </div>
                </Paper>
              ))}
            </Stack>
          </Card>
        </Grid.Col>

        <Grid.Col
          span={8}
          style={{
            position: 'relative',
            height: 'calc(100vh - 200px)',
            overflowY: 'auto'
          }}
        >
          <Paper
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDropOnNewGroupZone}
            p="xs"
            radius="md"
            h={180}
            style={{
              border: `2px dashed ${themeVars.colors.gray[4]}`,
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.1s ease',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              marginBottom: '1rem'
            }}
          >
            Create New Account Group
          </Paper>

          <Card shadow="xs" radius="md" p="1.5rem">
            <Text c="dark.3" fw={700}>Assigned Accounts</Text>
            <Space h={rem(20)} />
            <Stack gap="md">
              {GL_ACCOUNT_TYPES.map((type) => {
                const typeGroups = groupsByType[type];
                if (!typeGroups || typeGroups.length === 0) return null;

                return (
                  <div key={type} data-account-type={type}>
                    <Text c="dark.3" fw={700}>{type}</Text>
                    <Space h="xs" />
                    <Group gap="lg" style={{ paddingLeft: '2rem' }}>
                      {typeGroups.map((group) => (
                        <Card
                          key={group.id}
                          onDragOver={(e) => handleGroupDragOver(e, group.type)}
                          onDragLeave={handleGroupDragLeave}
                          onDrop={(e) => handleDropOnGroup(e, group.id, group.type)}
                          shadow="sm"
                          padding="sm"
                          radius="md"
                          withBorder
                        >
                          <Group justify="space-between" align="center">
                            {editingGroupId === group.id ? (
                              <TextInput
                                value={editingGroupName}
                                onChange={(e) => setEditingGroupName(e.currentTarget.value)}
                                onBlur={() => {
                                  handleGroupNameUpdate(group.id, editingGroupName);
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    handleGroupNameUpdate(group.id, editingGroupName);
                                  }
                                }}
                                autoFocus
                                styles={{
                                  input: {
                                    height: '24px',
                                    minHeight: '24px',
                                    width: '150px'
                                  }
                                }}
                              />
                            ) : (
                              <Text
                                fw={500}
                                onDoubleClick={() => {
                                  setEditingGroupId(group.id);
                                  setEditingGroupName(group.name);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {group.name}
                              </Text>
                            )}

                            <IconTrash
                              size={16}
                              style={{ cursor: 'pointer', color: themeVars.colors.gray[6] }}
                              onClick={() => handleDeleteGroup(group.id)}
                            />
                          </Group>
                          {group.type === 'Expense' && (
                            <>
                              <Space h="xs" />
                              <Select
                                size="xs"
                                placeholder="Select Department"
                                data={departmentOptions}
                                value={group.departmentId}
                                onChange={(value) => handleDepartmentChange(group.id, value)}
                                clearable
                              />
                            </>
                          )}
                          <Space h="xs" />
                          <Card padding="xs" radius="sm" withBorder bg="gray.0">
                            <Stack gap="xs">
                              {group.accounts.map((account) => (
                                <Paper
                                  key={account.id}
                                  draggable
                                  onDragStart={(e) => handleDragStart({
                                    ...account,
                                    previousGroupId: group.id
                                  }, e)}
                                  onDragEnd={handleDragEnd}
                                  p="xs"
                                  bg={themeVars.colors.gray[2]}
                                  radius="sm"
                                  fz="sm"
                                  shadow="sm"
                                  style={{ cursor: "move" }}
                                >
                                  <Text size="sm">{formatAccountName(account)}</Text>
                                </Paper>
                              ))}
                            </Stack>
                          </Card>
                        </Card>
                      ))}
                    </Group>
                  </div>
                );
              })}
            </Stack>
          </Card>
        </Grid.Col>
      </Grid>

      <Modal
        opened={opened}
        onClose={handleModalClose}
        title="Create New Account Group"
      >
        <form onSubmit={(e) => {
          e.preventDefault();
          if (!newGroupName.trim() || !draggedAccount) return;

          // Store the account type for scroll restoration
          const accountType = draggedAccount.type;

          createMapGroup({
            variables: {
              input: {
                mapId: id,
                name: newGroupName,
                accountType: draggedAccount.type
              }
            },
            onCompleted: (response) => {
              const mapGroupAccountInput = {
                input: {
                  mapId: id,
                  mapGroupId: response.createMapGroupMutation.mapGroup.id,
                  systemAccountId: draggedAccount.systemAccountId || draggedAccount.id,
                  connectionId: draggedAccount.connectionId
                }
              };

              createMapGroupAccount({
                variables: mapGroupAccountInput,
                onError: (error) => {
                  console.error('Error creating group account:', error);
                },
                onCompleted: (response) => {
                  console.log('Group account created:', response);
                  const newGroup: AccountGroup = {
                    id: response.createMapGroupAccount.mapGroupAccount.mapGroup.id,
                    name: newGroupName,
                    type: draggedAccount.type,
                    accounts: [draggedAccount]
                  };

                  setGroups(prevGroups => {
                    // First handle removing the account from its previous group if it exists
                    const updatedGroups = draggedAccount.previousGroupId
                      ? prevGroups.map(group => {
                        if (group.id === draggedAccount.previousGroupId) {
                          return {
                            ...group,
                            accounts: group.accounts.filter(account =>
                              account.id !== draggedAccount.id
                            )
                          };
                        }
                        return group;
                      })
                      : prevGroups;

                    // Then add the new group
                    return [...updatedGroups, {
                      id: response.createMapGroupAccount.mapGroupAccount.mapGroup.id,
                      name: newGroupName,
                      type: draggedAccount.type,
                      accounts: [{
                        id: draggedAccount.id,
                        systemAccountId: draggedAccount.systemAccountId || draggedAccount.id,
                        number: draggedAccount.number,
                        name: draggedAccount.name,
                        type: draggedAccount.type,
                        connectionId: draggedAccount.connectionId
                      }]
                    }];
                  });
                  if (!draggedAccount.previousGroupId) {
                    setAccounts(prevAccounts =>
                      prevAccounts.filter(account => account.id !== draggedAccount.id)
                    );
                  }
                  setNewGroupName('');
                  setDraggedAccount(null);
                  close();

                  // After all state updates, scroll to the relevant account type
                  requestAnimationFrame(() => {
                    const accountTypeElement = document.querySelector(`[data-account-type="${accountType}"]`);
                    if (accountTypeElement) {
                      accountTypeElement.scrollIntoView({ behavior: 'auto', block: 'start' });
                    }
                  });
                }
              });
            }
          });
        }}>
          <TextInput
            name="groupName"
            label="Group Name"
            required
            placeholder="Enter group name"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.currentTarget.value)}
          />
          <Group justify="flex-end" mt="md">
            <Button
              type="submit"
            >
              Create Group
            </Button>
          </Group>
        </form>
      </Modal>
    </Box>
  )
}
