/**
 * @generated SignedSource<<5a2fcf31a1e277c22d4187cd7b31f371>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateMapGroupMutationInput = {
  clientMutationId?: string | null;
  mapGroupId: string;
  name: string;
  newMapDepartmentId?: string | null;
  newParentId?: string | null;
};
export type accountMapUpdateMapGroupMutation$variables = {
  input: UpdateMapGroupMutationInput;
};
export type accountMapUpdateMapGroupMutation$data = {
  readonly updateMapGroupMutation: {
    readonly mapGroup: {
      readonly id: string;
      readonly mapDepartment: {
        readonly departmentName: string;
        readonly id: string;
      } | null;
      readonly name: string | null;
    } | null;
  } | null;
};
export type accountMapUpdateMapGroupMutation = {
  response: accountMapUpdateMapGroupMutation$data;
  variables: accountMapUpdateMapGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMapGroupMutationPayload",
    "kind": "LinkedField",
    "name": "updateMapGroupMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MapGroup",
        "kind": "LinkedField",
        "name": "mapGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MapDepartment",
            "kind": "LinkedField",
            "name": "mapDepartment",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "departmentName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountMapUpdateMapGroupMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountMapUpdateMapGroupMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "92725a9c1cb94cb2144bb361fc4f774c",
    "id": null,
    "metadata": {},
    "name": "accountMapUpdateMapGroupMutation",
    "operationKind": "mutation",
    "text": "mutation accountMapUpdateMapGroupMutation(\n  $input: UpdateMapGroupMutationInput!\n) {\n  updateMapGroupMutation(input: $input) {\n    mapGroup {\n      id\n      name\n      mapDepartment {\n        id\n        departmentName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b164a33a72df3f0939f06efc76cb46c8";

export default node;
