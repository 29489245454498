import { plaidLinkContentPlaidMutation as PlaidLinkContentPlaidMutationType } from "./__generated__/plaidLinkContentPlaidMutation.graphql"
import { Box, Group, Text, Button } from "@mantine/core"
import { PreloadedQuery, useLazyLoadQuery } from "react-relay"
import { PlaidLinkQuery } from "../plaid-link"
import styles from "../plaid-link.module.scss"
import { plaidLinkQuery } from "../__generated__/plaidLinkQuery.graphql"
import React, { useCallback, useState, useEffect } from 'react';
import { ConnectionHandler, graphql, useMutation } from "react-relay"
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
} from 'react-plaid-link';
import { notifications } from "@shared/ui/notifications"
import { useSearchParams } from "react-router-dom"

const plaidLinkContentPlaidMutation = graphql`
  mutation plaidLinkContentPlaidMutation(
    $input: PlaidOauthCallbackInput!
    $connections: [ID!]!
    ) {
    plaidOauthCallback(input: $input) {
        connection
          @appendNode(
            connections: $connections
            edgeTypeName: "SystemIntegration"
          ) {
          name
          id
          lastSync
          system {
            category
            name
            slug
          }
         creator {
          fullName
        }
        status
      }
      errors
      }
    }
`

type PlaidLinkContentProps = {
  queryRef: PreloadedQuery<plaidLinkQuery>
  onSuccess?: () => void
}

export const PlaidLinkContent = ({ queryRef, onSuccess: modalOnSuccess }: PlaidLinkContentProps) => {
  const [token, setToken] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams()
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');


  const data = useLazyLoadQuery<plaidLinkQuery>(
    PlaidLinkQuery,
    { companyId: queryRef.variables.companyId }
  )

  const [plaidCallback, plaidCallbackLoading] =
    useMutation<PlaidLinkContentPlaidMutationType>(
      plaidLinkContentPlaidMutation
    )


  useEffect(() => {
    if (isOAuthRedirect) {
      setToken(localStorage.getItem('link_token'));
      return;
    }
    if (!isOAuthRedirect && data.getPlaidLinkToken?.linkToken) {
      setToken(data.getPlaidLinkToken.linkToken);
      localStorage.setItem('plaidLinkToken', data.getPlaidLinkToken.linkToken);
    }
  }, [data.getPlaidLinkToken?.linkToken, isOAuthRedirect]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {

    const generalconnection = ConnectionHandler.getConnectionID(
      "root",
      "Connections_getIntegrations"
    )
    const integrationsConnection = ConnectionHandler.getConnectionID(
      "root",
      "ConnectionsTableFragment_getIntegrations"
    )

    const connections = [generalconnection, integrationsConnection]

    plaidCallback({
      variables: {
        input: {
          publicToken: publicToken
        },
        connections: connections,
      },
      onCompleted: (response) => {
        notifications.show({
          title: "Successful Connection",
          message: "The connection has been successfully added from the system.",
          variant: "success",
        })

        response.plaidOauthCallback?.connection?.id &&
          setSearchParams({
            connectionId: response.plaidOauthCallback.connection.id,
          })

        if (modalOnSuccess) {
          modalOnSuccess()
        }
      }
    })
  }, [plaidCallback, modalOnSuccess, setSearchParams]);



  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);
  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata);
  }, []);


  const config: PlaidLinkOptions = {
    // token must be the same token used for the first initialization of Link
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  if (isOAuthRedirect) {
    // receivedRedirectUri must include the query params
    config.receivedRedirectUri = window.location.href;
  }

  const {
    open,
    ready,
    // error,
    // exit
  } = usePlaidLink(config);

  React.useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);


  return isOAuthRedirect ? (
    <></>
  ) : (
    <Button
      onClick={() => open()}
      disabled={!ready}
      size="md"
      variant="filled"
    >
      Connect a bank account
    </Button>
  );
}