/**
 * @generated SignedSource<<b1fa124bf9931d0dd7c367b47170b7d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateMapMutationInput = {
  clientMutationId?: string | null;
  connectionIds: ReadonlyArray<string>;
  name: string;
};
export type mapsDrawerCreateMutation$variables = {
  input: CreateMapMutationInput;
};
export type mapsDrawerCreateMutation$data = {
  readonly createMap: {
    readonly clientMutationId: string | null;
    readonly map: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};
export type mapsDrawerCreateMutation = {
  response: mapsDrawerCreateMutation$data;
  variables: mapsDrawerCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMapMutationPayload",
    "kind": "LinkedField",
    "name": "createMap",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Map",
        "kind": "LinkedField",
        "name": "map",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mapsDrawerCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mapsDrawerCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "08d837570250c4e5673ec110e63394ab",
    "id": null,
    "metadata": {},
    "name": "mapsDrawerCreateMutation",
    "operationKind": "mutation",
    "text": "mutation mapsDrawerCreateMutation(\n  $input: CreateMapMutationInput!\n) {\n  createMap(input: $input) {\n    clientMutationId\n    map {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "93ea090cdb9d5a2e1c5bc6756f30c7a3";

export default node;
