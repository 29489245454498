import { graphql, useLazyLoadQuery } from "react-relay"
import { ColumnDef } from "@tanstack/react-table"
import { Table } from "@shared/ui/table"
import { mapsListQuery as MapsListQueryType } from "./__generated__/mapsListQuery.graphql"
import { useSearchParams } from "react-router-dom"
import { useUserStore } from "@shared/store"
import { useState } from "react"

const mapsListQuery = graphql`
  query mapsListQuery {
    getClientMaps {
      edges {
        node {
          name
          id
          creator {
            fullName
          }
          createdAt
        }
      }
    }
  }
`

type Map = {
  name: string | null
  id: string
  owner: string | null
  createdAt: string
}

type Props = {
  onRowClick: (id: string) => void
  columns: ColumnDef<Map>[]
}

export const MapsList = ({
  onRowClick,
  columns
}: Props) => {
  const { currentClient: { id: currentClientId } } = useUserStore()
  const [fetchKey, setFetchKey] = useState(0)
  const [, setSearchParams] = useSearchParams()

  const data = useLazyLoadQuery<MapsListQueryType>(
    mapsListQuery, 
    {},
    {
      fetchKey: `${currentClientId ?? ""}_${fetchKey}`,
      fetchPolicy: "network-only"
    }
  )

  const tableData = data.getClientMaps.edges.map(edge => ({
    name: edge.node.name,
    id: edge.node.id,
    owner: edge.node.creator.fullName,
    createdAt: new Date(edge.node.createdAt).toLocaleDateString()
  }))

  return (
    <Table
      columns={columns}
      data={tableData}
      onRowClick={(row) => {
        if (row.id) {
          onRowClick(row.id)
        }
      }}
    />
  )
}
