import { Stack, Text, rem } from "@mantine/core"
import { PropsWithChildren } from "react"

export const MapRow = ({ children, title }: PropsWithChildren<{ title: string }>) => {
  return (
    <Stack p={8} gap={rem(12)} w="100%">
      <Text fw={500} size="sm">{title}</Text>
      {children}
    </Stack>
  )
}
